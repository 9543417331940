var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BOverlay',{staticClass:"h-full",attrs:{"show":_vm.isLoading}},[_c('BaseModal',{attrs:{"isOpen":_vm.isOpenModal,"onModalClosed":_vm.toggleOpenModal,"backButtonText":"Voltar","withBackButton":true,"title":"Cadastre uma nova senha para o usuário","subtitle":"A senha antiga do usuário será apagada"}},[_c('div',{staticClass:"flex flex-col gap-5 w-full"},[_c('CustomInput',{attrs:{"label":"Nova senha","required":true,"placeholder":"Digite a nova senha","type":"password","inputStyle":"secondary"},model:{value:(_vm.newPassword),callback:function ($$v) {_vm.newPassword=$$v},expression:"newPassword"}}),_c('CustomInput',{attrs:{"label":"Confirme a senha","required":true,"placeholder":"Digite a nova senha novamente","type":"password","inputStyle":"secondary"},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}})],1),_c('CustomButton',{staticClass:"mt-2",attrs:{"onClick":_vm.changePassword,"disabled":_vm.buttonChangePasswordDisabled}},[_vm._v(" Enviar ")])],1),(_vm.currentId !== 'novo')?_c('entity-crud',_vm._b({staticClass:"h-full",attrs:{"tableVariant":"secondary"}},'entity-crud',{
			model: _vm.model,
			title: _vm.title,
			tableColumns: _vm.tableColumns,
			formColumns: _vm.formColumns,
			filter: _vm.filter,
			formStyleVariant: _vm.formStyleVariant,
			withPostalCode: _vm.withPostalCode,
			kind: _vm.kind,
			entityFormHeaders: _vm.entityFormHeaders,
			formContainerVariant: _vm.formContainerVariant,
			withEntityHeader: _vm.withEntityHeader,
			exportColumns: _vm.exportColumns,
			customButtons: _vm.customButtons,
		},false)):_c('div',{staticClass:"h-full flex flex-col gap-8"},[_c('CustomButton',{staticClass:"mb-2 self-start",attrs:{"variant":"tertiary","icon":"arrow-back.svg","iconStyle":"h-4 w-4 mr-1","onClick":_vm.onBack}},[_vm._v("Voltar")]),_c('InclusionType',{attrs:{"creationComponent":_vm.creationComponent,"title":"Criar usuário","model":_vm.userModel,"linkDownload":_vm.templateDownload}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }