







































































import { Component, Vue } from "vue-property-decorator";

import EntityCrud from "../entity/EntityCrud.vue";
import userModel from "@/api/user.model";
import {
	ContractKind,
	OccupationKind,
	translateContractKind,
	translateGender,
	translateOccupation,
	translateUserRole,
	translateWorkloadKind,
} from "@/interfaces/user";

import UserUpdatePassword from "./UserUpdatePassword.vue";
import { formatDayMonthYear, isValidCPF, isValidEmail } from "@/helpers/utils";
import InclusionType from "../InclusionType.vue";
import CustomButton from "@/layouts/components/Button.vue";
import BaseModal from "@/layouts/components/BaseModal.vue";
import CustomInput from "@/layouts/components/Input.vue";
import { showErrorAlert, showSuccessAlert } from "@/helpers";
import { BOverlay } from "bootstrap-vue";
import { spreadsheetTemplates } from "@/helpers/templateImport";
import corporateClientModel from "@/api/corporate_client.model";

@Component({
	components: { EntityCrud, UserUpdatePassword, BaseModal, CustomInput, CustomButton, BOverlay, InclusionType },
})
export default class Users extends Vue {
	model: any = userModel;
	formStyleVariant = "secondary";
	formContainerVariant = "primary";
	title = "Usuários";
	kind = "editionForm";
	withPostalCode = true;
	withEntityHeader = true;
	isOpenModal = false;
	isLoading = false;
	newPassword = "";
	confirmPassword = "";
	userModel = userModel;
	templateDownload = spreadsheetTemplates.users;

	customButtons = [{ variant: "primary", label: "Editar senha", buttonAction: this.toggleOpenModal }];

	tableColumns = [
		{
			key: "name",
			nameComplement: "lastName",
			name: "Nome",
			kind: "initialsBadges",
			style: "bold",
		},
		{ key: "lastName", kind: "invisible" },
		{ key: "email", style: "bold", name: "E- mail" },
		{ key: "role", name: "Área", style: "bold", openOptions: false },
	];
	filter = { role: { $in: ["HEALTH_PROMOTER", "GUARDIANSHIP", "ADMINISTRATOR"] } };

	entityFormHeaders = [
		{ key: "birthDate", kind: "age" },
		{ key: "gender", kind: "status" },
		{ key: "role", kind: "status" },
	];

	exportColumns = [
		{ key: "role", onTransform: translateUserRole },
		{
			key: "name",
			name: "Nome",
		},
		{ key: "lastName", name: "Sobrenome" },
		{ key: "treatmentForm" },
		{ key: "gender", onTransform: translateGender },
		{
			key: "cpf",
		},
		{ key: "rg" },
		{ key: "birthDate", onTransform: formatDayMonthYear },
		{
			key: "email",
		},
		{ key: "password", name: "Senha" },
		{ key: "phoneNumber", readOnly: false },
		{
			key: "address.postCode",
		},
		{ key: "address.street" },
		{ key: "address.number" },
		{ key: "address.complement" },
		{ key: "address.neighborhood" },
		{ key: "address.city" },
		{ key: "address.state" },
		{ key: "occupation", onTransform: translateOccupation },
		{
			key: "otherOccupation",
			name: "Outra profissão",
		},
		{ key: "councilNumber" },
		{ key: "jobFunction" },
		{ key: "jobFunctionDescription" },
		{ key: "startDate", onTransform: formatDayMonthYear },
		{ key: "contractKind", onTransform: translateContractKind },
		{
			key: "otherContractKind",
			name: "Outro tipo de contrato",
		},
		{ key: "workloadKind", onTransform: translateWorkloadKind },
		{ key: "workload" },
		{ key: "observation" },
	];

	formColumns = [
		{ key: "role", inputKind: "selectButtons", required: true, onTransform: translateUserRole },
		{
			key: "name",
			name: "Nome",
			required: true,
			isReadOnly: false,
		},
		{ key: "lastName", name: "Sobrenome", required: true, isReadOnly: false },
		{ key: "treatmentForm", isReadOnly: false, kind: "text" },
		{ key: "gender", isReadOnly: false, onTransform: translateGender },
		{
			key: "cpf",
			isReadOnly: false,
			required: true,
			validationField: isValidCPF,
			messageError: "CPF inválido",
		},
		{ key: "rg", mask: "rg", isReadOnly: false },
		{ key: "birthDate", isReadOnly: false, required: true, onTransform: formatDayMonthYear },
		{
			key: "email",
			isReadOnly: false,
			required: true,
			validationField: isValidEmail,
			messageError: "E-mail inválido",
		},
		{ key: "password", name: "Senha", readOnly: false, required: true, createOnly: true },
		{ key: "phoneNumber", readOnly: false },
		{
			key: "address.postCode",
			autoComplete: true,
			autoCompleteKeys: ["address.street", "address.neighborhood", "address.city", "address.state"],
		},
		{ key: "address.street", readOnly: false },
		{ key: "address.number", readOnly: false },
		{ key: "address.complement", readOnly: false },
		{ key: "address.neighborhood", readOnly: false },
		{ key: "address.city", readOnly: false },
		{ key: "address.state", readOnly: false },
		{ key: "occupation", readOnly: false, onTransform: translateOccupation },
		{
			key: "otherOccupation",
			isReadOnly: false,
			name: "Outra profissão",
			validationToShow: (entity: any) => {
				return entity.occupation === OccupationKind.OTHERS;
			},
		},
		{ key: "councilNumber", readOnly: false, kind: "text" },
		{ key: "jobFunction", readOnly: false },
		{ key: "jobFunctionDescription", kind: "textarea" },
		{ key: "startDate", onTransform: formatDayMonthYear },
		{ key: "contractKind", isReadOnly: false, onTransform: translateContractKind },
		{
			key: "otherContractKind",
			isReadOnly: false,
			name: "Outro tipo de contrato",
			validationToShow: (entity: any) => {
				return entity.contractKind === ContractKind.OTHER;
			},
		},
		{ key: "workloadKind", isReadOnly: false, onTransform: translateWorkloadKind },
		{ key: "workload", isReadOnly: false },
		{ key: "observation", isReadOnly: false },
		{
			key: "userCorporateClients",
			name: "Clientes corporativos atendidos",
			onTransform: this.formatReadOnlyCorporateClient,
			kind: "multiselect",
			relationKey: "corporateClient",
			formatEntity: true,
			selectAllButton: true,
			isReadOnly: false,
			options: [{}],
		},
	];

	userCorporateClientsField: any = {
		key: "userCorporateClients",
		name: "Clientes corporativos atendidos",
		kind: "multiselect",
		required: false,
		relationKey: "corporateClient",
		formatEntity: true,
		options: [{}],
	};

	creationComponent = {
		component: EntityCrud,
		props: {
			model: this.model,
			title: this.title,
			tableColumns: this.tableColumns,
			formColumns: this.formColumns,
			filter: this.filter,
			formStyleVariant: this.formStyleVariant,
			withPostalCode: this.withPostalCode,
			kind: this.kind,
			entityFormHeaders: this.entityFormHeaders,
			formContainerVariant: this.formContainerVariant,
			withEntityHeader: this.withEntityHeader,
			withBackButton: false,
			withTitle: false,
		},
	};

	userData: any = {};
	corporateClientModel = corporateClientModel;

	onBack() {
		this.$router.back();
	}

	get currentId() {
		return this.$route.params.id;
	}

	async mounted() {
		this.userData = this.$store.state.auth.activeUser;
		await this.getRelationFields();
	}

	toggleOpenModal() {
		this.newPassword = "";
		this.confirmPassword = "";
		this.isOpenModal = !this.isOpenModal;
	}

	async changePassword() {
		this.isLoading = true;
		try {
			this.isOpenModal = false;
			await this.model.update(this.currentId, { password: this.newPassword });
			showSuccessAlert("A senha foi alterada com sucesso.");
		} catch (error: any) {
			console.error(error);
			showErrorAlert(error?.friendlyMessage || "Ocorreu um erro. Tente novamente.");
		} finally {
			this.newPassword = "";
			this.confirmPassword = "";
			this.isLoading = false;
		}
	}

	get buttonChangePasswordDisabled() {
		if (this.newPassword && this.confirmPassword && this.newPassword === this.confirmPassword) {
			return false;
		} else {
			return true;
		}
	}

	formatReadOnlyCorporateClient(corporateClients: any[]) {
		if (!corporateClients) {
			return "";
		}
		const formatCorporateClients = corporateClients?.reduce(
			(stringCorporateClients, currentCorporateClient, indexCorporateClient) => {
				return `${stringCorporateClients} ${currentCorporateClient?.corporateClient?.name}${
					indexCorporateClient !== corporateClients.length - 1 && corporateClients.length > 1 ? "," : ""
				}`;
			},
			"",
		);

		return formatCorporateClients;
	}

	async loadCorporateClients() {
		const corporateClients = await corporateClientModel.search({});
		if (!this.formColumns.filter((field: any) => field.key === "userCorporateClients").length) {
			const userCorporateClientsField: any = {
				key: "userCorporateClients",
				name: "Clientes corporativos atendidos",
				kind: "multiselect",
				required: false,
				relationKey: "corporateClient",
				formatEntity: true,
				// onTransform: this.formatReadOnlyCorporateClient,
				options: corporateClients.map((corporateClient: any) => {
					return {
						name: corporateClient.name,
						value: { corporateClient: { id: corporateClient.id, name: corporateClient.name } },
					};
				}),
			};
			this.formColumns.unshift(userCorporateClientsField);
		} else {
			this.formColumns.find((field: any) => field.key === "userCorporateClients")!.options = corporateClients.map(
				(corporateClient: any) => {
					return {
						name: corporateClient.name,
						value: { corporateClient: { id: corporateClient.id, name: corporateClient.name } },
					};
				},
			);
		}
	}

	async getRelationFields(isLoading = true) {
		this.isLoading = isLoading;
		await this.loadCorporateClients();

		this.isLoading = false;
	}
}
